import React from 'react'
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Centralbody from '../components/Centralbody';

const Home = () => {

  return (
    <>
    <Topbar />
    <Centralbody />
    <Footer />
    </>
  )
}

export default Home
