import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {get_html_field_type, get_field_value, get_checked, get_html_field_classname, get_html_fieldlabel_classname, get_post_object_field_value, get_field_isvalid} from '../helper'

const Editform = () => {

    const [loading1,setloading1] = useState(false)
    const [saved,setSaved] = useState(false)
    const [saveErr,setSaveErr] = useState(null)
    const [btnDisabled,setBtnDisabled] = useState(false)
    const [objectData,setObjectData] = useState({})
    const [loading2,setloading2] = useState(false)
    const [structureData,setStructureData] = useState([])
    const {id} = useParams()

    useEffect(() => {
        setloading1(true)
        const getData = async() => {
          const url = process.env.REACT_APP_LOCAL_BACKEND_URL+process.env.REACT_APP_OBJECT_NEW_URL+'/1';
          const config = {
            headers: {'Authorization': '12345'},
            params: {}
          }
          try{
            const response = await axios.get(url,config)
            const recdata = response.data[0]
            if(recdata){
              setObjectData(recdata)
            }
            setloading1(false)
          } catch(error){
            console.log('Loading object data error: '+error)
            setloading1(false)
          }
        }
        getData()
      },[setloading1,id]
      )

    useEffect(() => {
        setloading2(true)
        const getData = async() => {
          const url = process.env.REACT_APP_LOCAL_BACKEND_URL+process.env.REACT_APP_STRUCTURE_GET_URL
          const config = {
            headers: {'Authorization': '12345'},
            params: {}
          }
          try{
            const response = await axios.get(url,config)
            const recdata = response.data
            if(recdata){
              let mydata=[];
              for(let k in recdata){
                mydata.push({name: k,...recdata[k]})
              }
              setStructureData(mydata)
            }
            setloading2(false)
          } catch(error){
            console.log('Loading object structure error: '+error)
            setloading2(false)
          }
        }
        getData()
      },[setloading2]
    )

    const handleChange = (value,field) => {
      let objectData2 = objectData;
      objectData2[field.name]=get_post_object_field_value(field,value);
      setObjectData({...objectData2})
  }

    const handleSave = () => {
      for (let k in structureData){
        if(structureData[k].isinvalid){
          return alert('Compila i campi richiesti')
        }
      }
      if(objectData.uuid){
        setSaved(false)
        setSaveErr(null)
        setBtnDisabled(true)
        const saveNow = async() => {
            const url = process.env.REACT_APP_LOCAL_BACKEND_URL+process.env.REACT_APP_OBJECT_POST_URL+'/'+objectData.uuid;
            const config = {
              headers: {'Authorization': '12345'},
            }
            const data = objectData
            try {
              const response = await axios.post(url,data,config)
              if(response.data===true){
                setSaved(true)
                setTimeout(()=>{
                  setSaved(false)
                  setBtnDisabled(false)
                },2000)
              } else {
                setSaveErr(response.data.error)
                setTimeout(()=>{
                    setSaveErr(null)
                    setBtnDisabled(false)
                },2000)
              }
            } catch(error){
                setSaveErr('Errore di rete al salvataggio!')
                setTimeout(()=>{
                    setSaveErr(null)
                    setBtnDisabled(false)
                },2000)
            } 
        }
        saveNow()
      }
    }

  return (
    <>
    <div className="container">
      
      <div className='container bg-dark form-header pt-4 pb-3'>
        <div className="row">
            <div className="col-12 col-md-2 col-lg-3 col-xl-4">
              <button className='btn mcr-btn btn-with-icon ms-0 mb-2' onClick={()=>{document.location.href=process.env.REACT_APP_LOCAL_SUBFOLDER+'/'}}><span className="material-symbols-rounded header-icon">arrow_back</span> Indietro</button>
            </div>
            <div className="col-12 col-md-8 col-lg-6 col-xl-4 pb-2">
              <h3 className='text-even'>Nuovo prodotto</h3>
            </div>
        </div>
      </div>

        {loading1 && loading2 && <h3 className='h3 text-even mt-5'>Caricamento...</h3>}
        {!loading1 && !loading2 && (!objectData || !structureData ) && <h3 className='h3 text-even mt-5'>Errore caricamento dati</h3>}
        {!loading1 && !loading2 && objectData && structureData && <div className='w-100'>

            <div className='row mt-5'>
            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            {
                structureData.map((field,idx) => {return <React.Fragment key={idx}><label className={get_html_fieldlabel_classname(field)}><b>
                {field.name.charAt(0).toUpperCase() + field.name.slice(1) + (field.name==='price'?' €':'')}</b></label>
                <input name={field.name} required={field.required} className={get_html_field_classname(field)+(!get_field_isvalid(field,get_field_value(field,objectData))?' is-invalid':'')} type={get_html_field_type(field)} value={get_field_value(field,objectData)??''} checked={get_checked(field,objectData)} onChange={(e)=>{handleChange(e.target.value,field)}} />
                </React.Fragment>}) }
                </div>
                </div>
            <div className="row">
                <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-end mt-4">
                    <button className='btn mcr-btn btn-with-icon ms-0 mb-2' onClick={()=>{handleSave()}} disabled={btnDisabled}><span className="material-symbols-rounded header-icon">save</span> Salva</button>
                </div>
            </div>
            {saved && <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-primary'><b>Salvato con successo!</b></p></div>
                </div>}
            {saveErr && <div className="row">
                <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-danger'><b>{saveErr}</b></p></div>
            </div>}

        </div>}
    </div>
    </>
  )
}

export default Editform
