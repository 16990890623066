import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../app/store'
import TableComponent from '../components/TableComponent';
import axios from 'axios';

function Index() {

  const [loading,setLoading] = useState(false)
  const [freeSearchString,setFreeSearchString] = useState('')

  const data = useSelector((state)=> state.data)
  const dispatch = useDispatch()

    useEffect(() => {
      setLoading(true)
      const getData = async() => {
        const url = process.env.REACT_APP_LOCAL_BACKEND_URL+process.env.REACT_APP_INDEX_GET_URL;
        const config = {
          headers: {'Authorization': '12345'},
          params: {}
        }
        try{
          const response = await axios.post(url,config)
          const recdata = response.data
          if(recdata.length){
            dispatch(setData(recdata))
          }
          setLoading(false)
        } catch(error){
          console.log(error)
          setLoading(false)
        }
      }
      getData()
    },[setLoading,dispatch]
    )

    function handleFreeSearchValue(event) {
      setFreeSearchString(event.target.value);
      // Add axios method to reload data as soon as API is present
    }
  
    return (
      <div className='w-100'>
        <div className='container-fluid'>
          <div className="row">
            <div className="col-12 text-end">
              <button className='btn mcr-btn btn-with-icon ms-0 mb-2' onClick={()=>{document.location.href=process.env.REACT_APP_LOCAL_SUBFOLDER+'/new'}}>
              <span className="material-symbols-rounded header-icon">add</span> Nuovo</button></div>
          </div>
          <div className="row">
            <div className="col-12 pb-2"><h3 className='text-even'>Lista Prodotti</h3></div>
          </div>
          <div className="row">
            <div className="col-12 pb-2">
              <label className='form-label text-even h6'>Ricerca libera:</label>
              <div className="input-group mb-3">
                <input type="text" value={freeSearchString} onChange={handleFreeSearchValue} className="form-control bg-dark text-even" style={{borderRight: 'none'}} placeholder="Cerca..." aria-label="Cerca..." aria-describedby="search-icon"/>
                <span className="input-group-text material-symbols-rounded bg-dark text-even" id="search-icon">search</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid" style={{height: 700}}>
          {loading && <h3 className='h3 text-even'>Caricamento...</h3>}
          {data.tableData.length && <TableComponent />}
          {!loading && !data.tableData.length && <h3 className='h3 mt-5 text-even'>Nessun dato presente</h3>}
        </div>
      </div>
    );
  }

  export default Index;
  