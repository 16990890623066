import { configureStore, createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
      logged_in: false,
      wrong_login: false,
      hash: ''
  },
  reducers: {
    setWrongLogin: (state,action) => {
      state.wrong_login = action.payload
      localStorage.setItem('wrong_login',state.wrong_login)
    },
    logIn: (state,action) => {
      state.logged_in=true
      state.wrong_login=false
      state.hash=action.payload.hash
    },
    saveLoginInLocalStorage: state => {
      localStorage.setItem('logged_id',state.logged_in?'true':'false')
      localStorage.setItem('wrong_login',state.wrong_login?'true':'false')
      localStorage.setItem('hash',state.hash)
    },
    loadLoginFromLocalStorage: state => {
      state.logged_in=localStorage.getItem('logged_id')==='true'?true:false
      state.wrong_login=localStorage.getItem('wrong_login')==='true'?true:false
      state.hash=localStorage.getItem('hash')
    },
    logOut: state => {
      state.logged_in=false
      state.hash=''
      state.badge_number=''
      localStorage.setItem('logged_id','false')
      localStorage.setItem('hash','')
    }
  }
})

const tableDataSlice = createSlice({
  name: 'data',
  initialState: {tableData: []},
  reducers: {
    setData: (state,action) => {
      state.tableData=action.payload
    }
  }
})

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    data: tableDataSlice.reducer
  },
});

export const {logIn,saveLoginInLocalStorage,loadLoginFromLocalStorage,logOut,setWrongLogin} = userSlice.actions
export const {setData} = tableDataSlice.actions

export default store;
