import { useParams } from 'react-router-dom'
import Editform from '../components/Editform'
import Newform from '../components/Newform'

const Edit = () => {

  const {id} = useParams()

  return (
    <>
      {id !== 'new' && <Editform/>}
      {id === 'new' && <Newform/>}
    </>
  )
}

export default Edit