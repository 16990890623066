import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from 'react-redux'
import { loadLoginFromLocalStorage } from "./app/store";
import Home from './templates/Home';

const App = () => {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(loadLoginFromLocalStorage())
  });
  
  /* Specific routing done in Centralbody */
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<Home />} />
      </Routes>
    </BrowserRouter>
    </>
  );
};

export default App;
