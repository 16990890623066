export const get_html_field_type = (field) => {
    let server_type = field.type
    if (server_type==='number') return 'number'
    if (server_type==='integer') return 'number'
    if (server_type==='boolean') return 'checkbox'
    if (server_type==='uuid') return 'hidden'
    if (server_type==='datetime') return 'datetime-local'
    return 'text' 
}

export const get_field_value = (field,objectData) => {
    if (field.name==='price' && !isNaN(objectData[field.name]) && objectData[field.name]!==''){
        return(Math.round(objectData[field.name]*100)/100)
    }
    if (field.type==='datetime'){
        if (!isNaN(objectData[field.name]) && objectData[field.name]!==null) {
            let o = new Date(objectData[field.name]);
            return o.getFullYear()+'-'+(o.getMonth()<9?'0':'')+(1+o.getMonth())+'-'+(o.getDate()<10?'0':'')+o.getDate()+'T'+(o.getHours()<10?'0':'')+o.getHours()+':'+(o.getMinutes()<10?'0':'')+o.getMinutes();
        }
        return null
    }
    return objectData[field.name]
}

export const get_post_object_field_value = (field,value) => {
    if (field.type==='datetime'){
        if (value===null) return null;
        let o = new Date(value)
        return o.getTime()
    }
    if (field.type==='boolean'){
        return (value==='true')?false:true
    }
    if (field.type==='number'){
        if (!isNaN(value)){
            return parseFloat(value)
        } else {
            return null
        }
    }
    if(field.type==='integer'){
        if (!isNaN(value)){
          return Math.round(parseFloat(value))
        } else {
          return null
        }
    }
    return value
}

export const get_field_isvalid = (field,value) => {
    if (field.required && (value==='' || value === null || typeof value === 'undefined')){
        field.isinvalid=true
        return false
    } else {
        field.isinvalid=false
    }
    return true
}

const convertHtmlFieldDateToTimeStamp = (dateString) => {
    let o = new Date(dateString)
    if(o) return o.getTime()
    return null
}

const get_backend_filter_value = (filterModelRow) => {
    if(filterModelRow['filter'])return filterModelRow['filter']
    if(filterModelRow['dateFrom'])return convertHtmlFieldDateToTimeStamp(filterModelRow['dateFrom'])
    if (filterModelRow['type']==='true')return true
    if (filterModelRow['type']==='false')return false
}

const get_backend_filter_function = (gridfunction) => {
    if(gridfunction==='contains_b')return 'contains'
    //if(gridfunction==='equals')return 'eq'
    //if(gridfunction==='greaterThan')return 'gt'
    //if(gridfunction==='lessThan')return 'lt'
    //if(gridfunction==='greaterThanOrEqual')return 'gte'
    //if(gridfunction==='greaterThanOrEqual')return 'lte'
    if(gridfunction==='true'||gridfunction==='false')return 'is'
    return gridfunction
}

export const get_backend_filter = (filterModel) => {
    
    let fields = []
    if(filterModel){
        for (let k in filterModel){
            //console.log('K: '+k)
            // Se filtro range, costruire i between con due campi gte e lte per lo stesso field
            if(filterModel[k]['type']==='inRange_b'){
                /* for (let j in filterModel[k]){
                    console.log('J: '+j+', VAL:'+filterModel[k][j])
                } */
                let filterRowFrom = {
                    field: k,
                    value: filterModel[k]['dateFrom']?convertHtmlFieldDateToTimeStamp(filterModel[k]['dateFrom']):filterModel[k]['filter'],
                    func: 'gte'
                }
                let filterRowTo = {
                    field: k,
                    value: filterModel[k]['dateTo']?convertHtmlFieldDateToTimeStamp(filterModel[k]['dateTo']):filterModel[k]['filterTo'],
                    func: 'lte'
                }
                fields.push(filterRowFrom)
                fields.push(filterRowTo)
            } else {
            // Se filtro normale, costruire il normale campo a filtro singolo
                let filterRow = {
                    field: k,
                    value: get_backend_filter_value(filterModel[k]),
                    func: get_backend_filter_function(filterModel[k]['type'])
                }
                fields.push(filterRow)
            }
        }
    }
    let bkf = {
        "filters": {
            "func": "and",
            "fields": fields
        },
        "sortby": {},
        "offset": 0,
        "count": false
    }
    console.log(bkf)
    return bkf
}

export const get_html_field_classname = (field) => {
    if (get_html_field_type(field)==='datetime-local' && (field.name==='created'||field.name==='deleted')){
        return 'form-control' //'d-none'
    }
    if (get_html_field_type(field)==='checkbox'){
        return 'form-check-input mcr-checkbox d-block'
    }
    return 'form-control'
}
export const get_html_fieldlabel_classname = (field) => {
    if (get_html_field_type(field)==='hidden'){
        return 'd-none'
    }
    if (get_html_field_classname(field) === 'd-none'){
        return 'd-none'
    }
    return 'form-label text-even mt-3'
}

export const get_checked = (field,objectData) => {
    let type=get_html_field_type(field),
        value=get_field_value(field,objectData)
    if(type==='checkbox'&&value===true)return true;
    return false;
}

export const convertTimestampToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleString('it-IT', options);
}