import React from 'react'
import Index from '../views/Index'
import Edit from '../views/Edit'
import Login from '../views/Login'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../app/store'
import { useParams } from 'react-router-dom'

const Centralbody = () => {

    const user = useSelector((state)=> state.user)
    const {id} = useParams()

    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logOut())
        document.location.href=process.env.REACT_APP_LOCAL_SUBFOLDER+'/'
    }

     /* New model creation logic managed in Edit */
    return (
        <>
        {
        user.logged_in && <><div className='w-100' style={{height: 50,marginTop: 90}}></div><div className='corner-right me-3' style={{position: 'fixed',zIndex: 99}}>
          <button className='mcr-btn small-btn' onClick={()=>{handleLogout()}}>Logout</button>
        </div></>
        }
  
        <div className='hero'>
          
          {!user.logged_in && <Login />}
          {user.logged_in && !id && <Index />}
          {user.logged_in && id && <Edit />}
        </div>
      </>
    )
}

export default Centralbody