import React, {useState, useCallback, useMemo,} from 'react'
import { AgGridReact } from 'ag-grid-react' // AG Grid Component
import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the grid
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../app/store'
import axios from 'axios';
import { convertTimestampToReadable, get_backend_filter } from '../helper'

const TableComponent = () => {

    const customValueFormatter = (key,p) => {
        if (key==='price' && !isNaN(p.value)) {
            return '€ ' + (Math.round(p.value*100)/100).toLocaleString()
        }
        if (key==='created' && !isNaN(p.value)) {
            return convertTimestampToReadable(p.value)
        }
        return p.value
    }

    const [fs,setFontSize] = useState(localStorage.getItem('fontSize')?localStorage.getItem('fontSize'):'16px')

    const handleSizeUp = () => {
        let curFs=fs.split('px')[0];
        let curNum=parseInt(curFs);
        if(!isNaN(curNum)){
            let newNum=curNum+2;
            setFontSize(newNum+'px');
            localStorage.setItem('fontSize',newNum+'px')
        }
    }
    const handleSizeDown = () => {
        let curFs=fs.split('px')[0];
        let curNum=parseInt(curFs);
        if(!isNaN(curNum) && curNum>=8){
            let newNum=curNum-2;
            setFontSize(newNum+'px');
            localStorage.setItem('fontSize',newNum+'px')
        }
    }

    const getFilterType = (key,value) => {
        let typesWithFilter = ['string','number','boolean']
        let dateFields = ['created','deleted']
        if (dateFields.includes(key)) return 'agDateColumnFilter'
        if (typesWithFilter.includes(typeof value)) return true
        
        return false
    } 

    const getFilterParams = (value) => {
        let filterParams = {}
        //if (typeof value === 'string') filterParams.filterOptions = ['contains']
        if (typeof value === 'string') filterParams.filterOptions = [
            {
                displayKey: 'contains_b',
                displayName: 'Contiene',
                predicate: ([filterValue], cellValue) => true
            }
        ]
        //if (typeof value === 'number') filterParams.filterOptions = ['equals','greaterThan','lessThan','inRange']
        if (typeof value === 'number') filterParams.filterOptions = [
            {
                displayKey: 'eq',
                displayName: 'Uguale a',
                predicate: ([filterValue], cellValue) => true
            },
            {
                displayKey: 'gt',
                displayName: 'Maggiore di',
                predicate: ([filterValue], cellValue) => true
            },
            {
                displayKey: 'gte',
                displayName: 'Maggiore o uguale a',
                predicate: ([filterValue], cellValue) => true
            },
            {
                displayKey: 'lt',
                displayName: 'Minore di',
                predicate: ([filterValue], cellValue) => true
            },
            {
                displayKey: 'lte',
                displayName: 'Minore o uguale a',
                predicate: ([filterValue], cellValue) => true
            },
            {
                displayKey: 'inRange_b',
                displayName: 'Nell\'intervallo',
                predicate: ([fv1, fv2], cellValue) => true,
                numberOfInputs: 2
            },
        ]
        filterParams.debounceMs = 200
        filterParams.maxNumConditions = 1
        return filterParams
    }

    // data: The data to be displayed.
    const data = useSelector((state)=> state.data)
    const dispatch = useDispatch()

    // Column Definitions: Defines the columns to be displayed.
    const precolDefs=[];
    for (const key in data.tableData[0]) {
        //console.log(key,typeof data.tableData[0][key])
        precolDefs.push({
            field: key,
            hide: (key==='uuid') ? true : false,
            cellClass: !isNaN(data.tableData[0][key]) ? 'ag-right-aligned-cell' : '',
            valueFormatter: p => customValueFormatter(key,p),
            filter: getFilterType(key,data.tableData[0][key]),
            filterParams: getFilterParams(data.tableData[0][key])
        });
    }
    //const [colDefs, setColDefs] = useState(precolDefs)
    const [colDefs] = useState(precolDefs)

    const onGridReady = (params) => {
        var columnState = JSON.parse(localStorage.getItem('myColumnState'))
        if (columnState) {
          params.api.applyColumnState({state:columnState, applyOrder:true})
        }
    }

    const onColumnChanged = (params) => {
        var columnState = JSON.stringify(params.api.getColumnState())
        localStorage.setItem('myColumnState', columnState)
    }

    const handleRowClicked = (event) => {
        const clickedId = event.data.uuid
        const url = process.env.REACT_APP_LOCAL_SUBFOLDER+'/'+clickedId
        window.location.href = url
    }

    const getFilteredData = async(filterModel) => {
        const url = process.env.REACT_APP_LOCAL_BACKEND_URL+process.env.REACT_APP_INDEX_GET_URL
        const config = {
            headers: {'Authorization': '12345'},
            params: {}
        }
        let requestBody=get_backend_filter(filterModel)
        //let requestBody={}
        try{
            const response = await axios.post(url,requestBody,config)
            const recdata = response.data
        if(recdata.length){
            dispatch(setData(recdata))
        }
        } catch(error){
            console.log(error)
        }
    }

    const handleFilterChanged = (event) => {
        // Ottieni i valori dei filtri
        const filterModel = event.api.getFilterModel();
        getFilteredData(filterModel)
          
    }
  
    return (
        // wrapping container with theme & size
        <>
        <div
         className="ag-theme-quartz-dark ag-grid-table" // applying the grid theme
         style={{fontSize: fs}} // the grid will fill the width of the parent container
        >
          <AgGridReact
              rowData={data.tableData}
              columnDefs={colDefs}
              onGridReady={onGridReady}
              onColumnMoved={onColumnChanged}
              onColumnResized={onColumnChanged}
              onColumnHeaderClicked={onColumnChanged}
              onRowClicked={handleRowClicked}
              onFilterChanged={handleFilterChanged}
              pagination={true}
              paginationPageSize={50}
              paginationPageSizeSelector={useMemo(() => {
                return [50,100,200,500,1000];
              }, [])}
              paginationNumberFormatter={useCallback((params) => {
                return params.value.toLocaleString();
              }, [])}
          />
        </div>
        <div className='row mt-1'>
            <div className='col-12 text-end'>
                <span className='label text-even'>Dimensione carattere: </span>
                <button type="button" className='btn btn-sm mcr-btn' style={{padding: '4px 12px'}} onClick={handleSizeDown}> - </button>
                <button type="button" className='btn btn-sm mcr-btn ms-1' style={{padding: '4px 8px'}} onClick={handleSizeUp}>+</button>
            </div>
        </div>
        </>
      )
  
  }

export default TableComponent;
