import React from 'react'
//import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { ReactComponent as Logo } from '../logo.svg'
//import Menu from './Menu'
/* import { logIn, saveInLocalStorage } from '../app/store' */

const Topbar = () => {

    //const { t } = useTranslation();
    const user = useSelector((state)=> state.user)

  return (
    <header>
        <div className="container h-100 d-flex justify-content-between align-items-center">
            
            <Logo alt="Logo" className="logo_img" />
            <div className="h-100 d-flex justify-content-end align-items-center">
                <span className="material-symbols-rounded header-icon">person</span>
                <span className='header-text me-3'>{user.logged_in ? 'Benvenuto Nome Cognome': 'Utente non loggato'}</span>
                <h2 className="text-white mx-2 mb-0">{'UI tecnica'}</h2>
                {/* <Menu/> */}
            </div>
        </div>

    </header>
  )
}

export default Topbar
